<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="dark"></b-spinner>
                  <b-spinner type="grow" variant="dark"></b-spinner>
                  <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
              </template>
          </b-overlay>
        <b-button v-b-modal.new-modal variant="success">ثبت منو</b-button>

        <b-card class="mt-4" :title="title">
            
           <b-table  responsive striped hover v-if="items.data" :fields="fields"  :items="items.data.data">
                
            <template v-slot:cell(created_at)="data">
                <p>{{ data.item.created_at | persianDate }}</p>
            </template>
            <template v-slot:cell(items)="data">
                    <router-link :to="{name:'admin.menu.items', params: {id: data.item.id}}" class="text-light" ><b-button  variant="warning"><i class="fas fa-bars"></i></b-button></router-link>
            </template>
            <template v-slot:cell(edit)="data">
                <b-button variant="primary" @click="editItem(data.index)"><i class="fas fa-edit"></i></b-button>
            </template>
            <template v-slot:cell(delete)="data">
                <b-button variant="danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></b-button>
            </template>
           </b-table>
           <pagination :limit="2" v-if="items.data" :data="items" @pagination-change-page="loadItems"></pagination>
           <div class="alert alert-danger" v-if=" items.data == '' ">
            <span>موردی یافت نشد ! </span>
         </div>
        </b-card>
        
        <b-modal id="new-modal" hide-footer  title="ثبت منو">
                <form @submit.prevent="newItem" id="new-item">
                    <b-form-group >
                        <label for="title">عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                        <b-form-input id="title" name="title" :disabled="disabled"></b-form-input>
                    </b-form-group>
                    <b-form-group label="توضیح" label-for="description">
                        <b-form-textarea id="description" name="description" :disabled="disabled"></b-form-textarea>
                    </b-form-group>
                    
                    <button class="btn btn-success" type="submit" :disabled="disabled">ثبت</button>
                </form>
        </b-modal>
        <b-modal id="edit-modal" hide-footer  title="ویرایش منو">
            <div v-if="edit.id">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <b-form-group >
                        <label for="title">عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                        <b-form-input id="title" name="title" v-model="edit.title" :disabled="disabled"></b-form-input>
                    </b-form-group>
                    <b-form-group label="توضیح" label-for="description">
                        <b-form-textarea id="description" name="description" v-model="edit.description" :disabled="disabled"></b-form-textarea>
                    </b-form-group>
                    
                    <input type="hidden" name="_method" value="PUT">
                    <button class="btn btn-primary" type="submit" :disabled="disabled">ویرایش</button>
                </form>
            </div>
        </b-modal>
    </div>
</template>
<script>   
    import mixins from './mixins/mixins'

    export default{
        mixins: [mixins],
        data(){
            return{
                url:'/api/admin/menus',
                fields:[
                    {key:"id", label:"شناسه"},
                    {key:"title", label:"عنوان"},
                    {key:"created_at", label:"تاریخ ثبت"},
                    {key:"items", label:"آیتم ها"},
                    {key:"edit", label:"ویرایش"},
                    {key:"delete", label:"حذف"},
                ],
                title:'منو',
                selected_status:'1',
                selected_filter_status:''
            }
        },
        methods:{
            
        },
        created(){
            this.loadItems()
        }
    }
</script>